import React from "react"
import { graphql } from 'gatsby'
import styled, { Box } from  '@xstyled/styled-components'

import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import NavBack from '../components/nav-back'
import SEO from '../components/seo'
import NodePageFragment from '../graphql-fragments/nodes/NodePageFragment' // eslint-disable-line no-unused-vars
import paragraphsResolver from '../components/paragraphsResolver'

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30rpx;
`

const DocumentsPage = ({ data, pageContext }) => {
  const page = data.drupal.nodeQuery.entities[0].entityTranslation;
  const { fieldBody } = page.fieldPlugins[0].entity.entityTranslation;
  const files = page.fieldPlugins.splice(1,);

  const lowerLang = pageContext.lang.toLowerCase()
  let capLang = lowerLang.charAt(0).toUpperCase() + lowerLang.charAt(1)
  capLang = capLang === 'Zh' ? 'ZhHant' : capLang
  const translatedData = data[`all${capLang}Json`].edges[0].node

  return(
  <Layout lang={pageContext}>
    <SEO title={page.title} />
    <Container>
      <Box row justifyContent="center">
        <Box col={{ xs: 1, md: 3 / 4, xl: 2 / 3 }}>
          <NavBack linkText={translatedData.cta.back} />
          <h1>{page.title}</h1>
          <h2>{page.fieldSubtitle.processed}</h2>
          <div dangerouslySetInnerHTML={{__html: fieldBody.processed}}/>
          <CardGrid>
           {paragraphsResolver(files)}
          </CardGrid>
        </Box>
      </Box>
    </Container>
  </Layout>
  )
}

export const query = graphql`
  query($lang: Drupal_LanguageId!) {
      drupal {
        nodeQuery(filter: {conditions: [{operator: EQUAL, field: "title", value: "Documents"}]}) {
          entities {
            entityTranslation(language: $lang) {
              ... on Drupal_NodePage {
                ...NodePageFragment
              }
            }
          }
        }
      }
    allEnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allEsJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allZhHantJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allKoJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allArJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allBnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
  }
`

export default DocumentsPage
